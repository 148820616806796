.navbar {
  background-color: #2e2e2e;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.navbar-container-right {
  display: flex;
}

.navbar-item {
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

.jpstats-title {
  margin: 0 10px;
  padding: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 1.3rem;
  color: #ffffff;
}

.navbar-container-right a:hover {
  color: #c37ae1;
}

.navbar-item,
.navbar-container-right a {
  transition: color 0.3s ease;
}

.hamburger{
  display:none;
  margin: 0 10px;
  padding: 10px;
}

.bar{
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: white;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Mobile */
.navbar {
  padding-left: 1%;
  padding-right: 1%;
}

/* Tablet */
@media (min-width: 1025px) {
  .navbar {
    padding-left: 10%;
    padding-right: 10%;
  }
}

/* Desktop */
@media (min-width: 1700px) {
  .navbar {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (max-width:768px) {
  .hamburger{
    display: block;
  }

  .hamburger.active .bar:nth-child(2){
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1){
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3){
    transform: translateY(-8px) rotate(-45deg);
  }
  .navbar-container-right{
    z-index: 9999;
    position: fixed;
    right: -100%;
    top: 70px;
    gap: 0;
    flex-direction: column;
    background-color: #2e2e2e;
    width: 30%;
    text-align: center;
    transition: 0.3s;
  }

  .navbar-item{
    margin: 16px 0;
  }

  .navbar-container-right.active{
    right: 0;
  }
}
