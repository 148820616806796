/* Changing padding based on display size to prevent
   main content from being awkwardly large. */

.dashboard {
  padding-left: 5%;
  padding-right: 5%;
  max-width: 1500px;
  margin: 0 auto; 
}

@media (min-width: 1230px) {
  .dashboard {
    padding-left: 10%;
    padding-right:10%;
  }
}

@media (min-width: 1248px) {
  .dashboard {
    padding-left: 15%;
    padding-right:15%;
  }
}

@media (min-width: 1700px) {
  .dashboard {
    padding-left: 20%;
    padding-right: 20%;
  }
}
.ch-subdomain-bg {
  fill: rgb(217, 217, 217);
}