.card {
  background: #202020;
  border-radius: 15px;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
}

.card-header {
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.card-content {
  justify-self: center;
  height: 100%;
}
