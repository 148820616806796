.stats-container {
  display: grid;
  gap: 20px;
  align-content: center;
  width: 100%;
  container-name: stats;
  container-type: inline-size;
  padding: 10px;
}
.center-wrapper {
  display: flex;
  height: 80%; 
}

.stat-item {
  color: #cacaca;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}
.stat-item h2 {
  margin: 0;
  -webkit-text-stroke: 0.7px #000000; 
}

.stat-item p {
  margin: 0;
  color: #ffff;
  font-size: 2.2vw;
  -webkit-text-stroke: 1px #000000; 
}


/* Switch stats to 4x1 and back
   to 2x2 on approporiate screens*/
  .stats-container {
    grid-template-columns: repeat(4, 4fr);
  }
  .stat-item p {
    font-size: 30px;
  }
  .stat-item h2 {
    font-size: 20px;
  }

  @media only screen and (max-width: 700px) {
    .stats-container {
      grid-template-columns: repeat(2, 2fr);
    }
  }